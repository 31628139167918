import React from 'react';
import { Text } from '@components/common';
import { DisplayCoordinatesProps } from './DisplayCoordinates.types';
import styles from './DisplayCoordinates.module.scss';
import { LoadingTypes } from '@commonTypes/common';

const DisplayCoordinates = ({ permission, sendingCoordinates, error, sendingError }: DisplayCoordinatesProps) => {
  return (
    <>
      {
        !!sendingError && (
          <Text className={styles.error}>
            {sendingError}
          </Text>
        )
      }
      {
        sendingCoordinates === LoadingTypes.fulfilled && (
          <Text className={styles.success}>
            Coordinates sent successfully!
          </Text>
        )
      }
      {
        permission && error ? (
            <Text className={styles.error}>
              {error}
            </Text>
          )
        : null
      }
    </>
  );
};

export default DisplayCoordinates;