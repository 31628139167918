import React from 'react';
import { Location, Information, DisplayCoordinates } from '@components';
import { Button } from '@components/common';
import { LocationRequestProps } from './LocationRequest.types';
import styles from './LocationRequest.module.scss';
import logo from '../../assets/images/LogoIcon.png';
import useLocationRequest from './useLocationRequest';
import { LoadingTypes } from '@commonTypes/common';

const LocationRequest = ({ route }: LocationRequestProps) => {
  const {
    permission,
    latitude,
    longitude,
    error,
    sendingError,
    sendingCoordinates,
    handleSendCoordinates,
  } = useLocationRequest();

  return (
    <div className={styles.container}>
      <img className={styles.logo} src={logo} alt='logo icon' />
      {route === 'location' ? <Location /> : <Information />}
      <Button
        className={styles.button}
        disabled={permission !== 'granted' || !(latitude && longitude) || sendingCoordinates === LoadingTypes.fulfilled}
        loading={sendingCoordinates}
        onClick={() => handleSendCoordinates()}
      >
        {route === 'location' ? 'Send Location' : 'Try to send location'}
      </Button>
      <DisplayCoordinates
        permission={permission}
        latitude={latitude}
        longitude={longitude}
        sendingCoordinates={sendingCoordinates}
        error={error}
        sendingError={sendingError}
      />
    </div>
  );
};

export default LocationRequest;