import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const useCodeFromUrl = () => {
  const { code } = useParams();

  useEffect(() => {
    if (!code) {
      console.error('Error extracting code from URL: Invalid URL structure');
    }
  }, []);

  return code;
};

export default useCodeFromUrl;